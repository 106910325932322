import React, { Component } from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import Img from "gatsby-image/withIEPolyfill"
import { mq, colors } from "../utils/presets"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faChevronSquareRight } from "@fortawesome/pro-light-svg-icons"
import { faChevronSquareLeft } from "@fortawesome/pro-light-svg-icons"
var decode = require("unescape")

const Title = styled.h1``

const Wrapper = styled.div`
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Topimage = styled(Img)`
  height: 215px;
  ${mq.tablet} {
    height: calc(100vh - 230px);
  }
`
const Textwrapper = styled.div`
  padding: 1rem;
  ${mq.tablet} {
    margin-right: 1rem;
    h1 {
      text-align: left;
      border-bottom: none;
      margin-bottom: 0.8rem;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const CustomGallery = styled.div`
  display: flex;
  flex-flow: row wrap;
  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 48%;
    height: 170px;
    object-fit: cover;
    margin: 1%;
    transition: all 0.3s;
    &:hover,
    &:focus {
      cursor: pointer;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
    &:focus {
      outline: 1px dotted grey;
      outline-offset: 5px;
    }
    div {
      width: 100%;
      margin: 0;
    }
    img {
      width: 100%;
    }
    ${mq.tablet} {
      width: 31%;
    }
    ${mq.desktop} {
      height: 250px;
    }
  }
`

const BottomNav = styled.nav`
  margin-top: 1rem;
  padding: 0.7rem;
  a {
    text-decoration: none;
    display: flex;
    flex-flow: row;
  }
  ul {
    margin: 0;
    display: flex;
    flex-flow: row;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    li {
      svg {
        align-self: center;
      }
      width: 50%;
      flex: 1 0 auto;
      .first {
        margin-left: 0.6rem;
      }
      .second {
        text-align: right;
        margin-right: 0.6rem;
      }
    }
  }
`

const GalleryImage = styled(Img)`
  width: 48%;
  height: 170px;
  object-fit: cover;
  margin: 1%;
  ${mq.tablet} {
    width: 31%;
  }
  ${mq.desktop} {
    height: 250px;
  }
`

export default class LightboxReferenz extends Component {
  constructor(props) {
    super(props)

    this.state = {
      photoIndex: 0,
      isOpen: false,
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state
    const { data } = this.props

    const images = data.wordpressWpReferenzen.acf.galerie.map(
      item => item.localFile.childImageSharp.fluid.src
    )

    const { previous, next } = this.props.pageContext

    const wptitle = data.wordpressWpReferenzen.title
    const wptitle_replace = wptitle.replace(/[0]/g, "")
    const wptitle_decoded = decode(wptitle_replace)

    return (
      <div>
        <Layout>
          <SEO
            title={wptitle_decoded}
            description={data.wordpressWpReferenzen.excerpt}
          />
          <Topimage
            objectFit="cover"
            objectPosition="50% 50%"
            fluid={
              data.wordpressWpReferenzen.featured_media.localFile
                .childImageSharp.fluid
            }
            alt={data.wordpressWpReferenzen.title}
          />
          <Wrapper>
            <Textwrapper>
              <Title
                dangerouslySetInnerHTML={{
                  __html: data.wordpressWpReferenzen.title,
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: data.wordpressWpReferenzen.content,
                }}
              />
            </Textwrapper>

            <CustomGallery>
              {data.wordpressWpReferenzen.acf.galerie.map((image, i) => {
                return (
                  <button
                    key={i}
                    onClick={() =>
                      this.setState({ isOpen: true, photoIndex: i })
                    }
                  >
                    <GalleryImage
                      key={i}
                      type="button"
                      objectFit="cover"
                      objectPosition="50% 50%"
                      fluid={image.localFile.childImageSharp.fluid}
                      alt={image.title}
                    />
                  </button>
                )
              })}
            </CustomGallery>

            <BottomNav>
              <ul>
                <li>
                  {previous && (
                    <Link to={`/referenzen/${previous.slug}`} rel="prev">
                      <FontAwesomeIcon
                        size="lg"
                        className="faicon"
                        icon={faChevronSquareLeft}
                        style={{ marginRight: ".2rem" }}
                      />{" "}
                      <div
                        className="first"
                        dangerouslySetInnerHTML={{ __html: previous.title }}
                      />
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link
                      to={`/referenzen/${next.slug}`}
                      rel="next"
                      style={{ justifyContent: "flex-end" }}
                    >
                      <div
                        className="second"
                        dangerouslySetInnerHTML={{ __html: next.title }}
                      />{" "}
                      <FontAwesomeIcon
                        size="lg"
                        className="faicon"
                        icon={faChevronSquareRight}
                        style={{ marginLeft: ".2rem" }}
                      />
                    </Link>
                  )}
                </li>
              </ul>
            </BottomNav>
          </Wrapper>
        </Layout>
        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </div>
    )
  }
}

export const query = graphql`
  query($id: Int!) {
    wordpressWpReferenzen(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    }
    wordpressWpReferenzen(wordpress_id: { eq: $id }) {
      acf {
        galerie {
          id
          slug
          title
          localFile {
            url
            childImageSharp {
              fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_tracedSVG
              }
            }
          }
        }
      }
    }
  }
`
